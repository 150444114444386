h2{
  margin-left: 1.5%;
  margin-top: 2%;
}

label{
  margin: 0;
}

.profile-input{
  margin-top: 4% !important;
  width: 90% !important;
}

.profile-img{
  width: 200px;height: 200px;
  object-fit: contain;
}

.profile-submit{
  width: 100%;
  float: right !important;
  
}
