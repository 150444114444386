.Rent {
  font-family: 'MMC Regular' !important;
}
main {
  width: 80%;
  margin: 0 auto;
}
.property-description, .property-section, .title-img, .info {
  font-family: 'MMC Regular' !important;
}
.main-component, .property-description, .property-section, .info {
  font-family: 'MMC Regular' !important;
}
.MuiTypography-root {
  font-family: 'MMC Regular' !important;
}


main h1 {
  font-weight: 400;
}

.review-stars {
  align-items: center;
  position: f;
}

main img {
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

main p {
  font-size: 1.25rem;
}

.location-btn {
  border: none;
  background: none;
  text-decoration: underline;
  font-size: 1rem;
  cursor: pointer;
}

.main-component .title .container {
  display: flex;
}

.main-component .title .container .review-stars p {
  margin: 0;
}

.main-component .title .container .share {
  margin-left: auto;
  margin-right: 20px;
}

.share-btn, .save-btn {
  border: none;
  font-size: 1rem;
  font-weight: 300;
  cursor: pointer;
  border-radius: 10px;
  background: none;
  text-decoration: underline;
}

.share-btn:hover,
.save-btn:hover {
  background-color: rgb(235, 235, 200); 
}

.property-details {
  display: flex;
  flex-wrap: wrap;
  /* border-radius: 10px; */
  overflow: hidden;
  position: relative;
  width: 100%;
}

/* .property-details .large-picture img, */
.property-details .small-picture img {
  width: 100%;
  height: 206px;
  object-fit: cover;
}

.property-details .small-pictures .small-picture {
  height: 100%;
}

.property-details .large-picture img,
.property-details .small-picture img {
  max-height: 100%;
}

.large-picture {
  flex: 1;
  margin-right: 10px;
  padding-right: 2px;
  object-fit: cover;

}

.large-picture img {
  max-width: calc(100% - 4px); 

  width: 100%;
  height: 417px;
  max-height: 410px;
  min-height: 200px;
  object-fit: cover;
}

.small-pictures {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
}

.small-picture {
  flex-basis: calc(50% - 4px);
  max-width: calc(100% - 6px);
  margin-bottom: 5px;
  min-height: 206px;
  max-height: 206px;
  min-height: 50px;
}

.small-picture img {
  flex: 1 0 30% !important; /* Control image width and allow wrapping */
  width: 100%;
  height: 204px;
  object-fit: cover;
}

.button-container {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
}

.showmore-btn {
  background-color: rgb(232, 232, 232);
  border: 1px solid black;
  border-radius: 5px;
  font-size: 0.8rem;
  transition: transform 0.5s ease;
}

.showmore-btn:hover {
  background-color: rgb(218, 218, 218);
}

.showmore-btn:active {
    transform: scale(0.90);
}

.showmore-img {
  width: 22%;
  margin-right: 2px;
}

@media (max-width: 900px) {
  .small-picture {
    margin-top: 2px;
  }
}

.small-picture img {
  transition: transform 0.3s, filter 0.3s;
  flex: 1 0 30% !important; /* Control image width and allow wrapping */

}

.large-picture img {
  transition: transform 0.3s, filter 0.3s;
}

.small-picture img:hover,
.large-picture img:hover {
  filter: brightness(80%);
  cursor: pointer;
}

.property-descriptions{
  margin-left: 3% !important; 
  margin-top: 3% !important;
  margin-bottom: 1% !important; 
  font-style: italic ;
  color: gray
}

.host-name{
  text-decoration: underline;
  cursor: pointer;
}

.info {
  font-size: 1rem;
  /* color: grey; */
  margin-top: 1%;
  /* font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important; */
  font-weight: 400 !important;
  margin-left: 3%;
}

.info1 {
  margin-left: 3%;
  /* font-family: Georgia, serif; */
}

.property-section-details {
  /* margin-left: 3%; */
  margin-top: 1.5%;
}

.small-pictures .small-picture:nth-child(2) {
  border-top-right-radius: 10px;
}

.divider {
  border-top: 2px solid rgb(221, 221, 221);
  width: 90%;
}

.divider1 {
  border-top: 2px solid rgb(221, 221, 221);
  width: 100%;
  margin-bottom: 2%;
}

.property-information {
  width: 70%;
}

.property-section {
  margin-top: 3%;
  margin-left: 3%;
  margin-bottom: 1.5%;
  font-weight: 500;
}

.subtitle{
  margin: 0
}

/* .property-description {
  display: flex;
  justify-content: space-between;
} */

.services {
  overflow: auto;
  width: 40%;
  margin-top: 3%;
}

.amenities-container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px; /* Adjust the space between items */
  justify-content: flex-start;
  align-items: center;
  margin-top: 2%;
  margin-left: 1%;
}

.amenity-item {
  display: flex;
  align-items: center;
}

.amenity-icon {
  width: 30px; /* Adjust icon size */
  height: 30px;
  margin-right: 10px; /* Space between icon and text */
}

.amenity-name {
  /* additional styling for the name if needed */
}

.pricecolw {
  width: 90%;
  background: rgb(255, 255, 255);
  /* min-height: 450px; */
  border-radius: 15px !important;
  border: 2px solid rgb(197, 197, 197);
  /* position: relative; */
  
  /* font-family: verdana; */
  /* float: left; */
  /* margin-left: 20px; */
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 13px 16px; */
}
.pricecol{
  text-align: center;
}

.frst-h3 {
  /* text-align: center;
  text-align: center; */
  margin-left: 20px;
  color: #000000;
}

.reserve {
  border-radius: 10px;
  color: rgb(255, 255, 255);
  font-size: 1.4rem;
  margin-top: 15px;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.15);
  cursor: pointer;
}

.reserve span {
  width: 80%;
  height: 35px;
  display: inline-block;
  padding: 10px 10px;
  background: none repeat scroll 0% 0% rgb(187, 1, 41);
  border-radius: 10px;
  box-shadow: 0px 0px 1px rgba(255, 255, 255, 0.002) inset, 0px 2px 0px rgba(255, 255, 255, 
  0.1) inset;
  text-align: center;
  line-height: 35px;
}

.reserve span:hover {
  background-color: brown;
}

.price-ul {
  list-style-type: none;
  padding: 0px;
  color: #fff;
  /* font-family: verdana; */
  font-size: 14px;
  margin: 0 auto;
  text-align: center;
  width: 70%;
  color: rgb(0, 0, 0);
}

.price-li {
  padding: 10px 0px;
}

.small-text {
  font-size: 0.8rem;
}
.small-text-price {
  font-size: 0.875rem;
  font-weight: 400;
}

.reviews {
  font-size: 0.9rem;
  /* font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  text-decoration: underline;
}


.pricee {
  display: inline-block;
  vertical-align: middle;
  margin-right: 120px;
  margin-top: 10px;
  font-weight: 700;
}

.n-reviews {
  display: inline-block;
  vertical-align: middle;
}

.price-info {
  display: flex;
  justify-content: space-between;
}

.price-info span {
  margin: 0 25px; 
  font-size: 1rem;
}
.price-info b {
  margin: 0 25px; 
  font-size: 1rem;
}

/* Add a vertical line in the middle */
.ant-picker-range {
  &::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 80%;
    background-color: black;
    top: 12%;
    left: 47%; 
    transform: translateX(-50%);
  }
}

.title-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.owner-profile-pic {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin-right: 10%;
  cursor: pointer;
  object-fit: cover;
}

.charge {
  font-size: 1rem;
  color: #706a6a;
}

.small-logo {
  width: 20px;
  height: 20px;
}

.host-title {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 3%;
}

.profile-pic-img {
  width: 70px;
  height: 70px;
  border-radius: 100%;
}

.joined {
  font-size: 1rem;
  color: rgb(119, 119, 119);
}

.review-id {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.during-stay {
  margin-top: 3%;
  width: 100%;
}

.host-comment {
  font-size: 1rem;
  color: rgb(77, 77, 77);
  width: 100%;
}

.contact-host-btn {
  height: 50px;
  width: 150px;
  background: none;
  border: 2px solid black;
  border-radius: 10px;
  font-size: 1.1rem;
  cursor: pointer;
  margin-top: 2%;
}

.contact-host-btn:hover {
  background-color: rgb(245, 245, 245);
}

.host-information {
  align-items: center;
}

.info-left,
.info-right {
flex: 1;
}

.info-right {
  margin-top: 10%;
}

.protec {
  font-size: 0.7rem;
  color: grey;
  margin-top: 5%;
  width: 100%;
}

.host-h3 {
  font-size: 1rem;
}

.google-maps {
  width: 100%;
  height: 550px;
}

iframe {
  position: absolute;
  width: 80%;
  z-index: 1;
}

.where {
  font-size: 1.9rem;
}

.show-more-container {
  width: 100%;
  display: flex;
  margin-top: 0.8%;
  flex-wrap: wrap;
  gap: 8px;
}

.show-more-picture {
  width: 24.5%;
}

.show-more-img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s, filter 0.3s;
}

.show-more-img:hover {
  filter: brightness(80%);
  cursor: pointer;
}

/* CSS */
@media (max-width: 600px) {
  .ant-picker-dropdown {
    /* overflow-x: scroll; */
    /* overflow-y: hidden; */
    /* z-index: ; */
  }
}
.xbutton {
  width: 2%;
  margin-top: 1%;
  margin-left: 1%;
  position: fixed;
  border-radius: 100%;
}

.imagelist {
  transition: transform 0.3s, filter 0.3s;
  border-radius: 15px;
}

.imagelist:hover {
  filter: brightness(80%);
  cursor: pointer;
}

.selectedimage-div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.selectedimage {
  width: 50%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  transition: opacity 0.8s ease-in-out;
}

.selectedimage.fade-out {
  opacity: 0;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(0%);
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: 1px solid black;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
  transform-origin: center;
}

.arrow:hover {
  background-color: rgb(242, 242, 242);
}

.arrow:active {
  transform: scale(0.8);
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.image-info {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  color: #333;
  font-size: 16px;
}

.xbutton {
  width: 2%;
  margin-top: 1%;
  margin-left: 1%;
  position: fixed;
}

.imagelist {
  transition: transform 0.3s, filter 0.3s;
  border-radius: 15px;
}

.imagelist:hover {
  filter: brightness(80%);
  cursor: pointer;
}

.selectedimage-div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.selectedimage {
  width: 50%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  transition: opacity 0.8s ease-in-out;
}

.selectedimage.fade-out {
  opacity: 0;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(0%);
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: 1px solid black;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
  transform-origin: center;
}

.arrow:hover {
  background-color: rgb(242, 242, 242);
}

.arrow:active {
  transform: scale(0.8);
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.image-info {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  color: #333;
  font-size: 16px;
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.all-buttons {
  display: flex;
  justify-content: center;
  gap: 5%;
}

.wewbtn {
  width: 14rem;
  gap: 4%;
  height: 3.2rem;
}

.left-column, .right-column {
  display: flex;
  flex-direction: column;
}

.custom-dialog-paper {
  max-width: 80% !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  margin: auto !important;
}

.carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
}

.carousel-caption {
  background: rgba(0, 0, 0, 0.7); /* Adjust the background color and opacity */
  padding: 20px;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .host-information {
    text-align: center;
  }

  .info-left,
  .info-right {
    text-align: center;
  }

  .review-id {
    justify-content: center;
  }
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 4px; /* Adds 4px gap between images */
}

.image-item {
  flex: 1 0 45%; /* Each item takes up about 45% of the width, leaving room for the gap */
}

.image-container img {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
}
.profile-container {
  display: flex;
  align-items: center; /* Align items horizontally and vertically */
  margin: 0 !important;
}

.profile-pic {
  margin-right: 15px; /* Space between image and text */
}

.profile-pic-img {
  width: 50px; /* Adjust the size of the image as needed */
  height: 50px;
  border-radius: 50%; /* To make the image circular */
}
.host-name-joindate {
  margin-top: 15px; /* Adjust the value to control the space */
}
.host-name-joindate h2 {
  margin: 0; /* Remove default margin */
  font-size: 18px; /* Adjust the font size */
}

.host-name-joindate .joined {
  font-size: 14px; /* Adjust the font size */
  color: gray; /* Optional: change color for joined date */
}

.scroll-with-mouse {
  max-height: 80vh; /* Adjust height as needed */
  overflow-y: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f0f0f0; /* For Firefox */
}

.scroll-with-mouse::-webkit-scrollbar {
  width: 8px; /* For Chrome, Safari */
}

.scroll-with-mouse::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 4px;
}

.scroll-with-mouse::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.airbnb-range-picker .ant-picker {
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  padding: 8px 12px;
  font-size: 14px;
  width: 90%;
  transition: all 0.3s;
}

.airbnb-range-picker .ant-picker:hover {
  border-color: #4096ff; /* Airbnb-like light blue */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.airbnb-range-picker .ant-picker-focused {
  border-color: #4096ff;
  box-shadow: 0 0 6px rgba(64, 150, 255, 0.5);
}

.airbnb-range-picker .ant-picker-input > input {
  color: #333; /* Text color */
  font-weight: 500;
}

.airbnb-range-picker .ant-picker-separator {
  margin: 0 8px; /* Align closer */
  font-size: 18px;
}
.ant-picker-dropdown {
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}
.ant-picker-input > input::placeholder {
  font-weight: bold;
}

/* Custom styles for the RangePicker */
.custom-date-picker {
  margin-bottom: 20px;
  position: relative;
}

/* Style the labels above the input */
.custom-label {
  position: absolute;
  top: -20px;
  left: 0;
  font-weight: bold;
  font-size: 14px;
  color: #333;
  background-color: #fff; /* Optional: background to make it readable */
  padding-left: 5px;
  padding-right: 5px;
}

/* Optional: Style the RangePicker input to look consistent */
.ant-picker-22 {
  width: 100%;
  padding-top: 20px; /* Add padding to make space for the label */
}
