.signup-form-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: auto;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  height: 90vh; /* Set a fixed height for the form container */
  position: relative;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;

}

.header-container1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: #fff;
  z-index: 10;
}



.form-scroll-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 120px); /* Adjust the height to allow scrolling */
  overflow-y: auto;
  padding-top: 30px; /* Adjust to give space under the header */
}

.signup-form1 {
  display: flex;
  flex-direction: column;
  width: 94%;
  align-items: center;
  justify-content: center;
}

.signup-form1 .MuiTextField-root {
  margin-bottom: 16px;
  width: 100%;
}
.css-16fl8la {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px; /* Set this same as the login form */
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  width: 35% !important;
}
.signup-btn {
  /* margin-bottom: 16px;
  padding: 12px;
  font-weight: bold;
  border-radius: 8px;
  background-color: #FF5A5F;
  color: #fff; */
  background-color: brown !important;
  color: #fff !important;

}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.separator .line {
  flex: 1;
  height: 1px;
  background-color: #111111;
}

.separator .or {
  margin: 0 10px;
  font-size: 14px;
  color: #111111;
  font-family: 'MMC Regular', sans-serif;
}

.social-login-buttons {
  width: 100%;
  margin-top: 20px;
}

.social-login-buttons .MuiButton-root {
  margin-bottom: 8px;
  width: 100%;
  border-radius: 8px;
}

.social-login-buttons .MuiButton-root:hover {
  background-color: #eeeeee;
}

.social-login-buttons .MuiButton-root .MuiButton-label {
  font-weight: bold;
  text-transform: none;
}

.already {
  font-size: 14px;
  text-align: center;
}

.already a {
  color: #0077FF;
}
