.navbar {
  position: sticky;
  top: 0;
  background-color: rgb(248 248 249);
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  
  border-bottom: 1px solid rgb(228, 228, 228);
  transition: transform 0.3s ease;

}

.logo {
  display: flex;
  align-items: center;
  margin-left: 30px;

}

.logo img {
  width: 55% !important;  /* Set the image width to 100% of its container */
  max-width: 160px; /* Set a maximum width for the image to prevent it from becoming too large */
  height: auto; /* Maintain the aspect ratio */
}

.logo-container {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  text-align: center; /* Center text */
  margin: 1rem; /* Margin around the logo */
}

.logo-link {
  text-decoration: none; /* Remove underline */
  transition: transform 0.3s; /* Smooth scale effect on hover */
}

.logo-link:hover {
  transform: scale(1.05); /* Slightly enlarge the logo on hover */
}

.logo-image {
  width: 50px; /* Set a specific width */
  height: auto; /* Maintain aspect ratio */
  transition: opacity 0.3s; /* Fade effect on hover */
}

.logo-image:hover {
  opacity: 0.8; /* Slightly fade on hover */
}

.logo-text {
  color: black; /* Text color */
  font-weight: 500; /* Bold text */
  text-shadow: -1px 2px 2px rgba(0, 0, 0, 0.3); /* Text shadow */
  /* font-family: 'Helvetica, sans-serif'; Font family */
  margin-left: 10px; /* Space between logo and text */
}

.logo-label {
  text-decoration: none; /* Remove underline from text */
  color: inherit; /* Inherit color from parent */
  cursor: pointer; /* Change cursor to pointer on hover */
}

@media (max-width: 600px) {
  .logo {
    /* display: flex;
    align-items: center; */
    margin-left:8px;
    width: 30%;
  
  }
  .logo-image {
    width: 80px; /* Reduce size on smaller screens */
  }
  .logo-text {
    font-size: 0.9rem; /* Reduce text size */
  }
}


.navbar-scrolled {
  transform: translateY(-100%);
}

.nav-btns {
  display: flex;
  align-items: center;
  height: 40px !important;
  font-family: 'MMC Regular' ;
}

.nav-btn{
  color: black !important;
  border: 1px solid #bb0129 !important;
  height: 100% !important;
  font-family: 'MMC Regular'  !important ;

}

.nav-btn:hover {
  background-color: #bb0129 !important;
  border: 1px solid #bb0129 !important;
  color: white !important;
}

.nav-btn-active {
  background-color: #bb0129 !important;
  color: white !important;
}

.nav-btnc{
  color: black !important;
  border: 1px solid #bb0129 !important;
  height: 100% !important;
  border-radius: 5px; /* Adjust this value to change the roundness */

}

.nav-btnc:hover {
  background-color: #bb0129 !important;
  border: 1px solid #bb0129 !important;
  /* color: white !important; */
}

.nav-btnc-active {
  background-color: #bb0129 !important;
  color: white !important;
}

.nav-btn1{
  color: black !important;
  height: 100% !important;
  width: 100% !important;
  margin-top: 5px !important;
  font-family: 'MMC Regular'  !important ;

}

.nav-btn1:hover {
  background-color: #bb0129 !important;
  color: white !important;
}

.nav-btn-left {
  justify-content: flex-start !important;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links li {
  text-transform: uppercase;
}

.nav-links a {
  text-decoration: none;
  color: #000000;
}

ul {
  margin-right: 30px;
}

.accountPopoverItem{
  width: 100%;
  font-size: 81%;
  color: black;
  border: 1px solid red;
}

.middle-button.active {
  border-bottom: 2px solid white;
}

.underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: white;
  transition: transform 0.3s ease, width 0.3s ease;
}

@keyframes underlineGrow {
  0% {
    transform: scaleX(0);
  }

  50% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.no-scroll {
  overflow: hidden;
}

.no-scroll1 {
  overflow: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: none;
}

@media (min-width: 1550px) {
  .middle-button {
    padding: 1% 2%;
    margin: 0 1%;
  }
}

.MuiBackdrop-root {
  transition: opacity 0s ease-in-out !important;
}

.MuiBackdrop-root.MuiBackdrop-invisible {
  opacity: 0 !important;
}

@media (max-width: 700px) {
  .navbar {
    height: 4.688rem;
  }

  .nav-links {
    z-index: 2000;
    /* gap: 0px; */
    margin-right: -20px;
    /* margin-left: 20%; */
  }

  .nav-btn {
    width: 100%;
  }
}

.rce-mbox {
  background-color: rgb(233, 233, 233) !important;
  color: black !important;
  max-width: 650px !important;
}

.rce-mbox-left-notch {
  fill: rgb(233, 233, 233) !important;
}

.rce-mbox-right-notch {
  fill: blue !important;
}

.rce-mbox.rce-mbox-right {
  background-color: blue !important;
  color: white !important;
  max-width: 650px !important;
}

.new-message {
  background-color: #FFFFE0;
  font-weight: bold;
}

.message-date {
  font-size: 10px;
  color: #bababa;
  text-align: right;
  margin-right: 20px;
  padding-left: 20px;
}


.react-flags-select {
  border: 10px  #bb0129 ; /* Same as button border */
  border-radius: 5px; /* Same as button border-radius */
  background-color: white; /* Same background as button */
  cursor: pointer; /* Change cursor on hover */
}

.react-flags-select:hover {
   background-color: rgba(238, 238, 238, 0.5); /* Similar hover effect */
}
.ReactFlagsSelect-module {
  border: 0 !important; /* Remove the border */
}
[class^="ReactFlagsSelect-module_fullWidthOptions__"] {
  width: 100px;
}
[class^="ReactFlagsSelect-module_selectOptions__"] {
  width: 100px;
}
[class^="ReactFlagsSelect-module_selectBtn__"] {
  border: 0 !important; /* Remove the border */
  outline: none; /* Remove the outline */
  font-weight: bold;
}

/* Remove borders on hover and focus */
[class^="ReactFlagsSelect-module_selectBtn__"]:hover,
[class^="ReactFlagsSelect-module_selectBtn__"]:focus {
  border: 0 !important;
  outline: none;
  color:#FFFFFF;
}
