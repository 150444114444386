
.form-group {
  display: flex;
  flex-direction: column;
  width: 105%;
}

.signup-label {
  margin-bottom: 5px;
  color: #333;
  align-self: flex-start;
}

.signup-input {
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: box-shadow 0.3s ease;
  align-self: center;
  margin-bottom: 5%;
  @media only screen and (max-width: 480px) {
    width: 100%; /* Make the input full-width on smaller screens */
  }
}

.signup-input:focus {
  box-shadow: 0 0 0 1.5px black;
}

.signup-btn {
  width: 75% !important;
  padding: 10px !important;
  background-color: brown !important;
  color: #fff !important;
  border: none !important;
  border-radius: 7px !important;
  cursor: pointer !important;
  /* margin-left: 2% !important; */
  margin-top: 5% !important;
  font-size: 17px !important;
  @media only screen and (max-width: 480px) {
    width: 100%; /* Make the button full-width on smaller screens */
  }
}

.signup-btn:hover {
  background-color: rgb(201, 32, 32) !important;
}

.signup-form.slide-up {
  transform: translateY(0);
}

.x-image {
  width: 15px;
  height: 15px;
  margin-top: 5px;
}

.x-btn-d {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-left: 15px;
}

.x-btn-d:hover {
  background-color: #faeebd;
}

.already {
  text-align: center;
  margin-top: 5px;
  color: grey;
  font-size: 1rem;
}

a {
  text-decoration: none;
  color: rgb(194, 40, 40);
}

.notification {
  text-align: center;
  margin-top: 3%;
}

.error-message {
  color: red;
}

.form-container {
  /* display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 460px;
  overflow-y: hidden; /* Enable vertical scrolling */
  padding: 30px;
  position: relative;

}


.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width:100%;
  @media only screen and (max-width: 600px) {
    flex-direction: column; /* Change to a column layout on smaller screens */
    align-items: center; /* Center items in mobile view */
    width:80%;
  }
}

.textSignUp {
  font-weight: bold;
  font-size: xx-large;
  margin-left: auto; /* Remove the left margin */
  margin-right:auto;
  @media only screen and (max-width: 600px) {
    margin-bottom: 15px; /* Add some spacing below the text in mobile view */
  }
}

.close-button {
  font-size: 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  color: #333;
  @media only screen and (max-width: 600px) {
    margin-top: 15px; /* Adjust spacing between text and close button in mobile view */
  }
}

.close-button:hover {
  color: #ff0000;
}

@media only screen and (max-width: 600px) {
  .container {
    flex-direction: column;
    align-items: flex-start; /* Align items to the start in mobile view */
  }

  .text {
    margin-bottom: 10px;
  }
}

.form-group .StripeElement {
  width: 100% !important; 
  /* max-width: 100%; */
  padding: 20px;  /*  Adjust padding to make the field larger */
  font-size: 18px; /* Increase font size */
}
