@media only screen and (max-width: 600px) {
  .card-title{
    font-size: 15px !important;
  }
  .card-exp{
    font-size: 13 px !important;
  }
  .card-image{
    width: 65px !important;
    height: 65px !important;
  }
}
@media only screen and (max-width: 400px) {
  .card-title{
    font-size: 12px !important;
  }
  .card-exp{
    font-size: 12px !important;
  }
  .card-image{
    width: 50px !important;
    height: 50px !important;
  }
}