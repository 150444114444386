.owner-container {
  width: 90%;
  margin: 0 auto;
}

.amenities {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.amenities-left, .amenities-right {
  width: 50%;
}

.amenities-item {
  gap: 10px;
}

.checkbox {
  margin-bottom: 2px !important;
  margin-right: 10px !important;
}

.divider-amenities {
  border-top: 1px solid rgb(196, 196, 196);
  margin-top: 5%;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Grey overlay with 50% opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998; /* Adjust the z-index as needed to ensure it's on top of other elements */
  cursor:default;
}

.loader-icon {
  background-size: none;
  color: white;
  z-index: 9999;
}



.small-cell{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    align-items: center;
}

@media screen and (max-width: 600px) {
  .small-cell {
    font-size: 10px; /* Adjust the font size for smaller screens */
    align-items: center;
  }
}
.popup-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.popup-field {
  margin-bottom: 15px;
}

.popup-field label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.popup-field input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.popup-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.submit-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #45a049;
}

.cancel-button:hover {
  background-color: #e53935;
}
