.site-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  background-color: white;
  text-align: center;
  min-height: 200px;
  margin-top: 5%;
  border-top: 2px solid rgb(188, 188, 188);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
}

.img-logo {
  width: 100px;
  height: 100px;
}

/* .footer-left {
  display: flex;
  align-items: center;
  width: 20%;
  flex-direction: column;
  gap: 18px;
  
} */

/* .logo {
  display: flex;
  align-items: center;
  margin-right: 50px;
  gap: 10px;
} */

/* .logo img {
  width: 70px;
  height: 70px;
  margin-right: 10px;
} */

/* .logo p {
  font-size: 20px;
  margin: 0;
  display: block;
} */

.textt {
  font-size: 16px;
  width:100%;
  text-align:justify;
}

.footer-middle {
  display: flex;
}

.footer-links {
  flex: 1;
  text-align: center;
}

.footer-links h1 {
  font-size: 25px;
  text-align: center;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.footer-links a {
  text-decoration: none;
  color: black;
}

.footer-links a:hover {
  color: blue;
}

.footer-right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-social {
  text-align: center;
}

.bot-text {
  color: black;
  font-style: oblique;
  margin-top: 10px;
}

.text {
  text-align: center;
  width:60%;
}

h1 {
  margin-bottom: 40px;
  text-decoration: underline;
}

/* Add a line above the social icons */
.footer-social::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px; /* Adjust the height as needed */
  background-color: #000000; /* Line color */
  margin: 10px 0; /* Adjust the margin as needed */
}

.bot-text::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px; /* Adjust the height as needed */
  background-color: #000000; /* Line color */
  margin: 10px 0; /* Adjust the margin as needed */
}

.footer-logos {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.footer-logos > .MuiButton-root:last-child {
  margin-right: 0;
}

.powered-by {
  color: blue;
}

.footer-h1 {
  color: #bb0129;
}


.footer-left .logo {
  text-align: center; /* Center the logo */
}

.footer-left .join-mailing-list {
  text-align: center; /* Center the button below the logo */
}

.footer-left .MuiButton-root {
  font-size: 16px;
  padding: 10px 20px;
  background-color: #bb0129; /* Red color, matching your theme */
}

.footer-left .MuiButton-root:hover {
  background-color: #9e011e; /* Darker red for hover effect */
}
@media (max-width: 600px) {
  .footer-left .logo {
    margin-left: -10px;
  }
}