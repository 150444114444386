.property-type-container {
    margin-top: 10%;
    margin-bottom: 10%;
    font-family: 'MMC Regular';
    
}
.fontfamily{
    font-family: 'MMC Regular';
  }
.toggle-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggle-btn {
    color: black;
    width: 100%;
}

.toggle-btn.Mui-selected {
    color: rgb(52, 52, 52) !important;
    background-color: rgb(238, 238, 238) !important;
}

.toggle-btn.Mui-selected:hover {
    background-color: rgb(227, 227, 227) !important; /* Set your desired hover color */
}

.property-type-container .centered-container .custom-toggle-btn-group {
    width: 300px;
}