.cards {
  /* width: 330px; */
  width: 18rem;
  height: 360px;
  border-radius: 10px !important;
  background-color:gray;
  /* font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
  position: relative;
  overflow: hidden;
}

.card img {
  max-width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.card h2 {
  margin: 10px 0;
  font-size: 1.5rem;
}

.card p {
  margin: 5px 0;
}

.card p:nth-child(1) {
  font-weight: bold;
}

.card p:nth-child(3) {
  font-style: italic;
  color: grey;
}

.card p:nth-child(4) {
  color: grey;
}

.card p:nth-child(5) {
  font-weight: bold;
}

.what::-webkit-scrollbar {
  width: 0px;
}

.what::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  z-index: 1;
}

.what::-webkit-scrollbar-track {
  background-color: transparent;
}

/* .whatever:hover {
  overflow-y: auto;
} */

/* .what:hover {
  overflow: auto;
  z-index: 1002;
  background-color: yellow;
} */

.what {
  height: 160px;
  overflow-y: auto;
  overflow-x:hidden;
}

.whatever {
  position: relative;
  /* z-index: 1; */
  /* overflow-y: auto; */
}