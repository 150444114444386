#google_translate_element {
    margin-top: 0px;
    text-align: center;
    display: none;
}

.goog-te-banner-frame {
    display: none !important;
}

body {
    top: 0px !important;
}

