@font-face {
  font-family: 'MMC Regular';
  src: url('./assets/fonts/MMC-Regular.ttf') format('truetype'); /* Adjust the path if necessary */
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
    font-family: "'MMC Regular' !important";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

