/* Container for the login form */
.login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px; 
  margin: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
}


/* Style for the form inputs */
.login-form {
  width: 100%;
  padding-top: 30px;
}

/* Styling for the social login buttons */
.social-login-buttons {
  width: 100%;
  margin-top: 16px;
}

/* Adjust button styles */
button {
  margin-bottom: 8px;
}

/* Title */
h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}
.login-btn {
   background-color: brown !important;
   color: #fff !important;
   
 }

 /* Separator */
.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.separator .line {
  flex: 1;
  height: 1px;
  background-color: #ccc;
}

.separator .or {
  margin: 0 10px;
  font-size: 14px;
  color: #666;
  font-family: 'MMC Regular', sans-serif;
}

.header-container {
  display: flex;
  justify-content: space-between;  /* Ensures space between h5 and close button */
  align-items: center;            /* Vertically center both elements */
  width: 100%;                    /* Make sure the container takes up the full width */
}

.close-btn {
  display: flex;
  justify-content: flex-end;      /* Aligns the close button to the right */
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;                /* Adjust size of 'x' button */
  cursor: pointer;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 24px;
  padding: 16px;
  border-radius: 8px;
}
