.fontfamily{
    font-family: 'MMC Regular'  !important ;
}


  
  /* Social Buttons */
  .social-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 80%;
    margin: 0 auto; /* Center horizontally */
    gap: 10px;
  }
  
  .social-btn {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    font-family: 'MMC Regular', sans-serif;
  }
  
  .facebook-btn {
    background-color: #3b5998;
    color: white;
  }
  
  .google-btn {
    background-color: #db4437;
    color: white;
  }
  
  .apple-btn {
    background-color: #333;
    color: white;
  }
  
  .email-btn {
    background-color: #0073e6;
    color: white;
  }
  