/* .dividerr {
  border-top: 2px solid rgb(221, 221, 221);
  width: 100%;
} */
.filter-window{
  font-family: 'MMC Regular';
}
.fontfamily{
  font-family: 'MMC Regular';
}

/* Style for the scrollbar itself */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Style for the track (progress bar) of the scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 10px; /* Round the edges */
}

/* Style for the handle (thumb) of the scrollbar */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
  border-radius: 10px; /* Round the edges */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar handle on hover */
}
