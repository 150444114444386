/* @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'); */
@font-face {
  font-family: 'MMC Regular';
  src: url('./assets/fonts/MMC-Regular.ttf') format('truetype'); /* Adjust the path if necessary */
}
.App{
  font-family: "'MMC Regular' !important";
}
.card-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin: 0 auto;
  background-color: rgb(248 248 249);
  gap: 3%;
  padding-bottom: 2%;
}

body {
  background-color: white;
  overflow: auto;
  flex-direction: column;
}

html, body {
  min-height: 50%;
  min-width: 50%;
  font-family: 'MMC Regular';

}

body::-webkit-scrollbar {
  width: 0px;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
}

body::-webkit-scrollbar-track {
  background: transparent;
}


.categories-container {
  position: relative;
  width: 90%;
  margin: 0 auto;
  overflow: hidden; /* Hide the overflow */
}

.categories-container:hover > .categories-scroll-btn{
  /* background-color: red !important; */
  visibility: visible !important;
  opacity: 1 !important;
  transition-delay: 0s !important;

}

.category img{
  width: 30px;
  height: 30px;
  object-fit: cover;
  transition: filter 0.3s ease;
}

.category {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #9e9e9e;
  min-width: 70px
}

.category:hover {
  color: #bb0129;
  transition: 0.3s;
}

.category-name{
  font-size: small;
  margin: 0px !important;
  padding-top: 7px !important;
  font-weight: 500;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.category.selected {
  color: #bb0129 !important;
}

.category img.selected {
  filter: invert(9%) sepia(71%) saturate(7419%) hue-rotate(342deg) brightness(99%) contrast(102%);
  color: #bb0129 !important;
}

@media (max-width: 768px) {
  .category {
    min-width: 100px;
  }
  .category-name {
    font-size: smaller;
  }
}

.myMainContainer{
  width: 80%;
  margin: 0 auto;
  margin-top: 2%;
}

.myContainer{
  width: 70%;
  margin: 0 auto;
}

.myContainer-item{
  background-color: white;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
}


@media only screen and (max-width: 600px) {
  .myContainer{
    width: 90%;
  }
}

@media only screen and (max-width: 400px) {
  .myContainer{
    width: 100%;
  }
}

@media (max-width: 768px) {
  .category {
    min-width: 100px; /* Adjust for smaller screens */
  }
  .category-name {
    font-size: smaller; /* Adjust font size for smaller screens */
  }
}

.myMainContainer{
  width: 80%;
  margin: 0 auto;
  margin-top: 2%;
}

.myContainer{
  width: 70%;
  margin: 0 auto;
}

.myContainer-item{
  background-color: white;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
}


@media only screen and (max-width: 600px) {
  .myContainer{
    width: 90%;
  }
}

@media only screen and (max-width: 400px) {
  .myContainer{
    width: 100%;
  }
}