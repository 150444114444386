/* Base styles */
.search-bar {
  display: flex;
  justify-content: center;
  background-color: rgb(248, 248, 249);
  min-height: 4rem;
  margin: 0 auto;
  margin-top: 2%;
  margin-bottom: 2%;
  font-family: 'MMC Regular';
  padding-left:50px;
  flex-wrap: wrap;
  width: 100%; /* Ensure it takes full width */
  max-width: 1200px; /* Set a max-width for larger screens */
}
.category-name {
  font-family: 'MMC Regular';
}
/* Styles for different screen sizes */
@media (max-width: 600px) {
  .search-bar {
    margin-left: 20px;
    flex-direction: column;
    align-items: center; /* Center items in column direction */
    padding: 10px; /* Padding adjusted for small screens */
  }
  
  .search-segment,
  .filters-btn {
    width: 100%; /* Full width for small screens */
    margin: 5px 0;
  }

  .search-input {
    width: 100%; /* Full width for small screens */
  }

  .filters-img {
    width: 20px; /* Adjust icon size for small screens */
    height: 20px;
  }
}

@media (max-width: 890px) {
  .search-bar {
    margin-left: 10px;
    padding: 15px; /* Increased padding for medium screens */
    flex-direction: column;
    align-items: center; /* Center items in column direction */
  }

  .search-segment,
  .filters-btn {
    width: 90%; /* Adjust the width for both buttons */
    margin: auto;
    margin-top: 0.5rem;
  }

  .filters-btn {
    width: 45%; /* Adjust the width for both buttons */
    margin-bottom: 0.5rem;
  }

  .search-segment {
    margin-bottom: 0.5rem;
  }

  .search-input {
    width: 100%; /* Full width for small screens */
    margin-left: 0;
    margin-top: 0;
  }

  .filters-btn,
  .search-button button {
    width: 100%; /* Full width for small screens */
    margin: 5px 0;
  }
}

/* Ensure buttons and input fields fit within the search bar without becoming too large */
.search-segment {
  flex-grow: 1;
  display: flex;
}

.filters-btn {
  background-color: #e2725b !important;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 10px !important;
  height: 50px;
  font-size: 1rem;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.filters-btn:hover {
  background-color: #d85c4a;
}

.search-input {
  flex-grow: 1;
  margin-left: 14px;
  margin-top: 6px;
  border: none;
  background: none;
  outline: none;
  transition: border-bottom 0s;
  width: 15%;
}

::placeholder {
  font-size: 1rem;
}

.search-input[type="date"] {
  /* font-family: 'Arial', sans-serif; */
  font-size: 13px;
  font-weight: bold;
  color: #333;
}

.search-filters {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.filters-img {
  width: 25px;
  height: 25px;
  padding-right: 5px;
}

.filter-dropdown {
  position: relative;
  background-color: rgb(255, 255, 255);
}

.filter-button {
  cursor: pointer;
}

.filter-options {
  position: absolute;
  top: 100%;
  left: -90%;
  width: 20rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  border-radius: 20px;
  border: 1px solid rgb(203, 203, 203);
  overflow-y: auto;
}

.filter-options.show {
  max-height: 500px;
  opacity: 1;
  pointer-events: auto;
  background-color: white;
  padding: 16px;
}

.rangePicker {
  border-color: #b9b9b9; /* Replace with your default color */
}

.rangePicker:hover {
  border-color: rgba(0, 0, 0, 0.87); /* Replace with your hover color */
}

.rangePicker:focus {
  border-color: #e2725b; /* Replace with your focus color */
}

/* Additional styles if needed */
