/* General Styling */
/* body {
  margin: 0;
  padding: 0;
  background-color: #f4f4f9;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
} */

/* Form Container */
form {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px 30px;
}

h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Form Fields */
form label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

form input,
form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  box-sizing: border-box;
}

form input:focus,
form select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Submit Button */
form button {
  width: 100%;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

form button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 480px) {
  form {
    width: 90%;
    padding: 20px;
  }

  form button {
    font-size: 14px;
  }
}
