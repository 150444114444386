.alertn {
  font: 14px Arial, sans-serif;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 15px 25px;
  border-radius: 8px;
  width: 80%;
  max-width: 400px;
  z-index: 1000;
  transition: all 0.3s ease;
}

.alertn p {
  margin: 0;
  color: #333;
  line-height: 1.5;
}

.alertn .buttonsn {
  float: right;
  margin-left: 15px;
}

.alertn button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 13px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.alertn button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.alertn button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.4);
}
