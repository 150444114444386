.inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 15%;
}

.input2 {
  width: 50%;
  height: 2rem;
  font-size: 1.2rem;
  border-radius: 7px;
  border: 1px solid grey;
  text-align: center;
}

.slider-container {
  margin-top: 10%;
  margin-bottom: 10%;
}

.slider-container .rc-slider-handle {
  width: 1.75rem;
  height: 1.75rem;
  margin-top: -12px;
  opacity: 1;
}

.price-slider .rc-slider-track {
  background-color: rgb(0, 0, 0);
}