

  .ads {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Default to two columns */
    gap: 2px; /* Space between grid items */
    justify-items: center; /* Center items horizontally */
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
   
    .ads {
      grid-template-columns: 1fr ; /* Change to one column on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    
  }
  
  
  /* ---- reset ---- */
body {
  margin: 0;
  font: normal 75% Arial, Helvetica, sans-serif;
}
canvas {
  display: block;
  vertical-align: bottom;
}
/* ---- tsparticles container ---- */
#tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0d47a1;
  background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.github {
  bottom: 10px;
  right: 10px;
  position: fixed;
  border-radius: 10px;
  background: #fff;
  padding: 0 12px 6px 12px;
  border: 1px solid #000;
}

.github a:hover,
.github a:link,
.github a:visited,
.github a:active {
  color: #000;
  text-decoration: none;
}

.github img {
  height: 30px;
}

.github #gh-project {
  font-size: 20px;
  padding-left: 5px;
  font-weight: bold;
  vertical-align: bottom;
}

.spinner {
  border: 8px solid #f3f3f3; /* Light gray */
  border-top: 8px solid #bb0129; /* Blue color for the rotating part */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite; /* Infinite rotation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}