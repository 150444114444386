/* .room-button {
  width: 3rem;
  box-shadow: 0 0 0 1px grey;
  transition: box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  padding: 14px;
  border-radius: 15%;
}

.room-button:hover {
  box-shadow: 0 0 0 2px rgb(241, 241, 241);
}

.room-filter-buttons {
  margin-bottom: 10%;
}

.room-btn.Mui-selected {
  background-color: black;
}

.tttt {
  background-color: red;
} */
.Bedroom-btns {
  font-family: 'MMC Regular';
}
.Bedroom-btns .r-h3 {
  font-family: 'MMC Regular';
}
.tttt {
  color: black !important;
  border-color: black !important;
}

.tttt:hover { 
  background-color: rgb(241, 241, 241) !important;
  border-color: black !important;
}

.blackButton {
  background-color: black !important;
  color: white !important;
}

.blackButton:hover {
  background-color: black !important;
}