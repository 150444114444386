/* .profile-container{
  width: 70%;
  margin: 0 auto; 
}

.profile-item-container{
  background-color: white;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
}

.last-container{
  margin-bottom: 2%;
} */

/* Profile.css */

.profile-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-item-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.last-container {
  margin-bottom: 20px;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .profile-container {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
  }

  .profile-item-container {
    flex: 1;
    min-width: calc(33% - 20px);
  }

  .last-container {
    flex-basis: 100%;
  }
}

/* Navbar integration styles */
.navbar {
  margin-bottom: 20px;
}
