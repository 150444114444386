.location-search-input {
  width: 100%;
}

.autocomplete-dropdown-container {
  position: absolute;
  background-color: #fff;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  z-index: 1000;
}

.suggestion-item--active {
  background-color: #f3f3f3;
  cursor: pointer;
}

.suggestion-item {
  font-size: 1rem;
}
